.optionsWrapper {
  display: flex;
  gap: 8px;
}

.responsePara {
  background: #ffffff;
  border: 1.5px solid #cfcdc9;
  border-radius: 5px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  word-break: break-all;
  width: 100%;
  min-height: 36px;
}

.labelStyle {
  color: rgba(0, 0, 0, 0.6);
}
