.OnlineCourse .btn.btn-primary {
  background-color: #8167cc !important;
  border: #8167cc !important;
}

.OnlineCourse a {
  color: #8167cc;
}

.tabs a {
  display: inline-block;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 8px 5px;
}

.tabs a.current {
  color: #8167cc !important;
  font-weight: 500;
  position: relative;
}

.OnlineCourse span[data-rbd-drag-handle-context-id] {
  cursor: default;
}

.interactiveTooltipHolder {
  padding-left: 5px;
  position: relative;
}

.success_svg {
  margin-bottom: 2px;
}

.cartTitle {
  color: #272522;
}

.interactiveToolTip {
  position: absolute;
  z-index: 100000;
  background-color: #ffffff;
  border: 1px solid rgba(108, 93, 211, 1);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  left: 40%;
  transform: translateX(-40%);
  bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 250px;
  box-shadow: 0px 10px 50px rgb(0 0 0 / 10%);
}

.interactiveToolTip.bottom {
  bottom: auto;
  top: 30px;
}

.interactiveToolTipPopper {
  z-index: 99999 !important;
}

.toolTipBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1101;
  transition: opacity 0.3s ease;
}

.interactiveToolTip .toolTipContent {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.interactiveToolTip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 40%;
  transform: translateX(-40%);
  border-width: 12px;
  border-style: solid;
  border-color: rgba(108, 93, 211, 1) transparent transparent transparent;
}

.interactiveToolTip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 40%;
  transform: translateX(-40%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.interactiveToolTip.bottom:before {
  top: -25px;
  border-color: transparent transparent rgba(108, 93, 211, 1) transparent;
}

.interactiveToolTip.bottom:after {
  top: -20px;
  border-color: transparent transparent white transparent;
}

.rightAlign {
  left: 370%;
  transform: translateX(-70%);
}

.leftAlign,
.leftAlign:before,
.leftAlign:after {
  left: 30%;
  transform: translateX(-30%);
}

@media screen and (max-width: 780px) {
  .interactiveToolTip {
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
  }
  .interactiveToolTip:before {
    left: 50%;
    transform: translateX(-50%);
  }
  .interactiveToolTip:after {
    left: 50%;
    transform: translateX(-50%);
  }

  .rightAlign,
  .rightAlign:before,
  .rightAlign:after {
    left: 70%;
    transform: translateX(-70%);
  }

  .leftAlign,
  .leftAlign:before,
  .leftAlign:after {
    left: 30%;
    transform: translateX(-30%);
  }
}

.tabs a.current:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80%;
  height: 4px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #8167cc;
  transform: translateX(-50%);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursorPointer {
  cursor: pointer;
}

.chevronDown {
  transition: all 0.3s ease;
}

.chevronDownactive {
  transform: rotate(180deg);
}

.imagePreview {
  width: 100%;
  max-width: 400px;
  height: auto;
  position: relative;
}

.imagePreview img {
  width: 100%;
  height: auto;
}

.navigationPanelHolder {
  /* max-width: 400px; */
  width: 100%;
  flex: 1;
}

.navigationPanel {
  background-color: #fcfcfc;
  border: 1px solid #e2e2e2;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
}

.editingPanelHolder {
  flex: 1;
}

.editingPanelCard {
  position: sticky;
  top: 0;
}

.topSectionWrapper {
  gap: 24px;
}

.editingPanel {
  border: 1px solid #c4c4c4;
  height: 100%;
  overflow-y: auto;
  max-height: 600px;
}

/* component: sectionTree */

.newSectionPlaceholder {
  border: 1px dashed #8167cc;
  color: #8167cc;
  cursor: pointer;
}

.newLessonPlaceholder {
  border: 1px dashed #8167cc;
  color: #8167cc;
  cursor: pointer;
  font-size: 14px;
}

.sectionRow {
  border: solid 1.5px #cbcdd2 !important;
}

.sectionRow.active {
  border: 1.5px solid #8167cc !important;
}

.sectionRow.error {
  border-color: rgba(191, 38, 0, 1) !important;
  background-color: rgba(251, 235, 234, 1) !important;
}

.sectionRow span {
  font-size: 14px;
}

.sectionRowIcon {
  cursor: grab;
  background: #f6f6f6 !important;
}

.sectionRowIcon.error {
  background: #f2bcbc !important;
}

.lessonRowWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-left: 1px dotted #cbcdd2 !important;
}

.lessonRow {
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  border: solid 1.5px #cbcdd2;
  cursor: pointer;
  border-radius: 6px;
  width: 90%;
  flex: 1;
}

.positionUnset {
  position: unset;
  display: block;
}

.lessonRow:hover {
  background-color: #fbfbfb !important;
}

.lessonRow.active {
  background-color: #f6f3fe !important;
  border-color: #8167cc;
}

.lessonRow.error {
  border-color: rgba(191, 38, 0, 1);
  background-color: rgba(251, 235, 234, 1) !important;
}

.lessonRowIcon {
  cursor: grab;
  background-color: #f6f6f6;
}

.lessonRowIcon.active {
  background-color: #e9e0ff !important;
}

.lessonRowIcon.error {
  background-color: #f2bcbc !important;
}

/* component: elementOptions */

.elementOptionsHolder {
  border: 1px dashed #493ab1;
  color: #493ab1;
  text-align: center;
  border-radius: 12px;
}

.elementOptionsDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 0px;
}

.elementOptionsDivider:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
}

.elementOptionsDivider img {
  z-index: 2;
}

.disabledOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 600px;
  background-color: rgba(255, 255, 255, 0.6);
}

.customInput input {
  border: none;
  width: 100% !important;
}

.flex1 {
  flex: 1;
}

.modal {
  background-color: #fff;
  border-radius: 6px;
  max-height: 90%;
  max-width: 800px;
  padding: 20px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modalSm {
  background-color: #fff;
  border-radius: 6px;
  max-height: 200px;
  max-width: 400px;
  padding: 20px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.modal_close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 18px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  bottom: 0;
  height: 160px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 95%;
}

.descriptionBox {
  margin: -16px !important;
}

.fixedCloseBtn {
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 10;
  background-color: #fff;
  border-radius: 50px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

/* buttons */
.customButton {
  border-radius: 5px;
  padding: 6px 18px;
  color: #fff;
}

.customButton:disabled {
  opacity: 0.5;
}

.customButton.primary {
  background: #8167cc 0% 0% no-repeat padding-box;
  border: 1px solid #8167cc;
}

.customButton.danger {
  background: #d61a1a 0% 0% no-repeat padding-box;
  border: 1px solid #d61a1a;
}

.customButton.transparent {
  background: transparent;
  border: 1px solid #fff;
  color: rgba(0, 0, 0, 0.7);
  padding: 3px 6px;
}

.customButton.outline {
  background-color: #fff;
  border: 1px solid #8167cc;
  color: #8167cc;
}

.customButton.outline:hover {
  background-color: #8167cc;
  color: #fff;
}

.customButton.large {
  font-size: 18px;
  padding: 10px 24px;
}

.customButton.small {
  font-size: 14px;
  padding: 6px 8px;
}

.dangerouslySetInnerHTML p {
  margin: 0;
}
/* dropdown */
.dropdown {
  position: relative;
}
.dropdown .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: transparent;
  cursor: default;
}

.dropdown span {
  cursor: pointer;
}

.dropdown .list {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 0;
  min-width: 160px;
  max-width: 300px;
  box-shadow: 0px 1px 6px #0000002e;
  right: 0;
  z-index: 200;
}
.dropdown .list .listItem {
  padding: 5px 10px;
  display: block;
  cursor: pointer;
}

.dropdown .list .listItem:hover {
  background-color: #ededed;
}

.table {
  width: 100%;
}

.table tr {
  border-bottom: solid 1px #e5e5e5;
}

.table th {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px;
}

.table td {
  padding: 8px;
}

/* switch tabs */
.switchTabs {
  border-radius: 5px;
  background-color: #ededed;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  margin: 20px 0;
}

.switchTabs span {
  background-color: transparent;
  padding: 6px 14px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}

.switchTabs span.active {
  background-color: #8167cc;
  color: #fff;
}

.reviewSectionsBox {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 460px;
  padding: 16px;
}

.sectionReviewRow {
  background-color: #f9f7ff;
  padding: 16px;
  width: 500px;
  margin-bottom: 4px;
  border-radius: 6px;
}

.sectionReviewInfo span {
  font-size: 14px;
}

.contentRow {
  border: solid 1px #e5e5e5;
}
.contentRow.active {
  border: 1px solid #8167cc;
}

.imageRow img {
  max-width: 100%;
}

.modalReviewNavigation {
  padding: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: #ffffff;
}

.textPrimary {
  color: #8167cc;
}

.textOverlay {
  position: fixed;
  bottom: 0;
  height: 160px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 95%;
}

.ihf_container {
  background-color: #d7d4e5;
  border: 1px solid #483d89;
  border-radius: 8px;
  margin-top: 25px;
}

.ihf_included_title {
  font-size: 16px;
  font-weight: 600;
}

.ihf_included_textbox {
  max-width: 90%;
  padding: 10px;
}

.textSaveDescBtn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #493ab1;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 50px;
  padding: 10px 30px;
  color: #fff;
  border: none;
  width: 190px;
}

.textDivider {
  border: 1px solid transparent;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  width: 70%;
  min-width: min(400px, 80vw);
  margin: -4px auto;
  max-width: 90vw;
}

.textDivider hr {
  border-color: #ccc;
}

.textDivider span {
  position: absolute;
  top: 25%;
  left: calc(
    50% - (24px + 16px) / 2
  ); /* 24px is the width, 8px is the padding*/
  background-color: #fff;
  padding: 0 8px;
  color: #999;
}

.wistiaAuthContainer {
  display: block;
  border: 1px dashed #ccc;
  padding: min(160px, 10vh) 0;
  margin: 0 auto 12px;
  text-align: center;
  background-color: #f3f3f3;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}

.wistiaAuthContainer.disabledAuthContainer {
  pointer-events: none;
  cursor: not-allowed;
}
.wistiaAuthContainer.disabledAuthContainer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #9993;
  backdrop-filter: blur(6px);
  filter: grayscale(0.5);
  pointer-events: none;
  cursor: not-allowed;
}

.medialModalRoot {
  z-index: 99 !important;
}

.mediaModal {
  max-width: 90vw !important;
  width: 80%;
}

.modalContainer {
  padding: 15px;
  min-height: 66vh;
  min-width: 45vw;
  max-height: 72vh;
  overflow-y: auto;
}

.tabContainer {
  padding: 20px 0px;
}

/* .borderImage {
  width: 100%;
  height: 100%;
} */

.driveBtn {
  position: absolute;
  top: 54%;
  left: 40%;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexStartColumn {
  width: 40%;
  display: flex;
  justify-content: flex-start;
}

.flexEndColumn {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.searchInput {
  height: 40px;
  width: 100%;
}

.iconThumbnail {
  height: 138px;
  width: 138px;
  border-radius: 5px;
  position: relative;
  border: 1px solid #e7e6e4;
  margin: 8px 3px;
}

.selector input {
  position: absolute !important;
  z-index: 99 !important;
  top: 0.2rem !important;
  left: 0.2rem !important;
}

.mediaRadioButton {
  cursor: pointer;
  background-size: 99%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 138px;
  height: 138px;
  border-radius: 5px;
}

.dashedBorder {
  border: 1px dashed #8167cc;
  padding: 1rem;
}

.previewDivHeading {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.videoFrame {
  height: 200px !important;
  width: 200px !important;
  border-radius: 10px !important;
}

.mediaPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediaTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-align: center;
}

.iconDisplay {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.detailsCheckbox {
  position: absolute;
  top: 45%;
}
.contentRowText {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16x;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #493ab1;
}

.circleNumber {
  background: #cccccc;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

.errorText {
  color: rgba(191, 38, 0, 1);
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #493ab1; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
}

.tooltipPlacementBottom {
  margin-top: 8px !important;
}

.contentModal {
  padding: 16px 0px 0px 0px;
}

.tableRow {
  background: #fff;
  border: none;
}

/* Need this because when dragged position is added from the library, adding this to handle tableRow row looks smooth */
.tableRow td:nth-child(1) {
  min-width: 50px;
}

.tableRow td:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.tableRow td:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
}

.tableRow td:nth-child(4) {
  min-width: 150px;
}

.tableRow td:nth-child(5) {
  min-width: 50px;
}

.tableRow td:nth-child(6) {
  min-width: 50px;
}

.offeringSelect {
  height: auto !important;
}

.recommended_header {
  font-weight: bold;
  color: #ff0000;
}

.draggableCard {
  padding: 10px 20px 24px 20px;
  width: 100%;
}

.thead {
  /* max-width: 117px; */
  width: 100%;
}

.thead tr {
  width: 100%;
}

.thead th:nth-child(1) {
  min-width: 50px;
}

.thead th:nth-child(2) {
  min-width: 150px;
  max-width: 150px;
}

.thead th:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
}

.thead th:nth-child(4) {
  min-width: 150px;
}

.thead th:nth-child(5) {
  min-width: 50px;
}

.thead th:nth-child(6) {
  min-width: 50px;
}

.select {
  padding: 9.165px 24px 9.165px 12px !important;
  line-height: normal !important;
}

.cardTitle {
  font-weight: 500;
  font-size: 16px;
}

.draggableCardHeader {
  padding-left: 20px;
  padding-right: 20px;
}

.draggableCardSubTitle {
  color: #a09a92;
}

.editOfferingFieldCtn {
  display: "flex";
  justify-content: "flex-end";
  align-content: "center";
  cursor: "pointer";
}

.autoLessonDesc {
  color: #493ab1;
  font-weight: 500;
  line-height: 20px;
}
.borderLeft {
  border-left: 1px dotted #cbcdd2 !important;
}

.backbtn {
  padding: 0 !important;
  margin-right: 10px !important;
}

.backbtnSvg {
  color: rgba(0, 0, 0, 0.54) !important;
}

.w500 {
  width: 500px;
}

.progressWrapper {
  position: relative;
}

.videoUploadCircularProgress {
  height: 28px !important;
  width: 28px !important;
}

.progressWrapper svg {
  color: white;
}

.videoUploadingProgress {
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}

@media screen and (max-width: 780px) {
  .tabs a {
    font-size: 12px;
  }

  .topSectionWrapper {
    gap: 0px;
  }

  .assesmentModalContent {
    height: 60vh;
    overflow: auto;
  }

  .sectionTitle {
    font-size: 16px;
    color: #272522;
    line-height: 20px;
    font-weight: 700;
    margin: 30px 16px;
  }

  .editLessonMobileWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 45px;
    top: 0;
    background-color: white;
    z-index: 100;
    overflow-y: auto;
    padding: 0;
  }

  .editingPanel {
    border: none;
  }

  .editingPanelHolder {
    padding-bottom: 40px;
  }

  .reviewSectionsBox {
    border: none;
    border-radius: 6px;
    width: auto;
    padding: 0px;
  }

  .dashedBorder {
    border: none;
    padding: 1rem;
  }

  .iconThumbnail {
    height: 120px;
    width: 120px;
  }

  .mediaRadioButton {
    height: 120px;
    width: 120px;
  }

  .driveBtn {
    top: 50%;
    left: 21%;
  }

  .modalContainer {
    padding: 15px;
    min-height: 75vh;
    min-width: 45vw;
    max-height: 85vh;
  }

  .mobileModalRoot {
    z-index: 999 !important;
  }

  .mobileModalFooter {
    background-color: #ffffff !important;
    z-index: 9999 !important;
    position: fixed !important;
    bottom: 0px !important;
    width: 100% !important;
  }

  .dropdown {
    border: 1px solid #e7e6e4;
    border-radius: 12px 0px 12px 12px;
    background-color: #ffffff;
    position: fixed;
    bottom: 4rem;
    width: 99%;
    top: auto;
    left: 2px;
    right: 2px;
    display: block;
    border-radius: 12px 12px 12px 12px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
    z-index: 99999;
  }

  .contentRowText {
    font-size: 14x;
    line-height: 17px;
  }

  .mobileModalRoot {
    z-index: 999 !important;
  }

  .mobileModalFooter {
    background-color: #ffffff !important;
    z-index: 9999 !important;
  }

  .discList {
    font-size: 12px;
  }

  .tableRow td {
    min-width: auto;
  }

  .draggableCard {
    padding: 10px 20px 24px 20px;
    overflow-x: scroll;
  }

  .cardTitle {
    font-size: 14px;
  }

  .formControlLabelClassName span {
    font-size: 11px !important;
  }
}
