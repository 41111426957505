.question {
  display: flex;
  gap: 8px;
}

.questionContent {
  padding-inline-start: 18px;
  margin-left: 14px;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #272522cc;
}

.questionContent div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.questionContent span {
  color: #00b779;
  font-size: 12px;
}

.questionContent svg {
  font-size: 14.2px;
  margin-top: -3px;
}

.question__card__wrapper {
  padding-bottom: 13px;
  min-width: 60vw;
  max-width: 60vw;
}

.question__card {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
}

.question__summary {
  padding: 12px 16px !important;
}

.question__content {
  margin: 0 !important;
}

.question__content__container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.question__content__container button {
  padding: 0;
  text-align: right;
}

.question__ctas {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.question__ctas svg {
  font-size: 20px;
}

.question__number {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #93a9d9;
  margin-top: 7px;
}

.question__number div {
  flex: 1;
  border-left: 1px solid #cbd9f8;
}

.question__title {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #272522;
}

.question__title span {
  color: #ff0c00;
}

.question__description {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #27252266;
  margin-top: 6px;
}

.question__dragging {
  background-color: #dcd9f2 !important;
}

.question__dragIcon {
  color: #948ad9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -13px;
}

.question__summary_expandIcon {
  transition: transform 0.2s ease-in-out !important;
  color: #948ad9;
}

.question__summary__expandedIcon {
  transform: rotate(180deg);
}

.question__editIcon {
  color: #493ab1;
}

.question__deleteIcon {
  padding: 3px;
}

.question__actionCtas {
  display: flex;
  gap: 8px;
}

.addCtaWrapper {
  display: flex;
  gap: 12px;
  position: relative;
  min-height: 56px;
  align-items: center;
}

.addCtaIndexElContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  align-self: stretch;
  color: transparent;
}

.addCtaIndexEl {
  height: 100%;
  border-left: 1px solid #cbd9f8;
}

.dummyQuestionNumber {
  height: 0;
}

.addCta {
  text-transform: none !important;
  color: #493ab1 !important;
  font-size: 16px !important;
  font-weight: 500;
  padding: 0 !important;
}

.addCta svg {
  font-size: 20px;
  margin-top: -2px;
}

.actionCta {
  justify-content: flex-start !important;
  text-transform: none !important;
  padding: 12px 20px !important;
}

.welcomeItem {
  display: flex;
  color: #93a9d9;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  margin-left: 4.38px;
  height: 48px;
  gap: 14px;
}

.circle {
  height: 7px;
  width: 7px;
  background-color: #93a9d9;
  border-radius: 50%;
}

.welcomeItem__dotContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalBar {
  flex: 1;
  border-left: 1px solid #cbd9f8;
}

.welcomeItem__dotContainer__title {
  display: flex;
  align-items: center;
}

.welcomeItem__title {
  margin-top: -3.5px;
  user-select: none;
}

.welcomeItem__title__reverse {
  margin-top: auto;
  margin-bottom: -3.5px;
}

.subLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #27252266;
  gap: 6px;
  margin-top: 8px;
}

.subLabel div {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subLabel img {
  max-width: 7.41px;
}

.starRating {
  display: flex;
  align-items: center;
  color: #cfcdc9;
  padding: 0 7.55px;
  padding-bottom: 11.55px;
}

.starRating svg {
  font-size: 31.02px;
}

.numberRating {
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #cfcdc9;
  opacity: 0.5;
  margin: 0 12px;
  margin-bottom: 14px;
  user-select: none;
}

.numberRating td {
  border: 1.5px solid #cfcdc9;
  font-size: 14px;
  color: #272522;
  width: 32px !important;
  min-width: 24px !important;
  height: 40px;
  text-align: center;
}

.selectedRating {
  background-color: lightgray;
}

.datePicker {
  margin: 0 12px !important;
  margin-bottom: 14px !important;
}

.textDisable {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.radioGroup {
  margin: 0 12px !important;
}

.questionSubtitleCotnainer {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.imageResonseRoot {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 82px;
}

.responseImage {
  max-width: 100%;
  border-radius: 8px;
}

@media screen and (max-width: 780px) {
  .question__card__wrapper {
    flex: 1;
    max-width: none;
  }

  .question__description {
    font-size: 14px;
    line-height: 17px;
  }
}
