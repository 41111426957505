.container {
  padding: 32px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  margin: 14px 0 12px 0;
}

.content {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 16px;
}
