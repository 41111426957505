.optionContainerBoxStyles {
  margin-bottom: 10px;
}

.optionContainerStyles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inputContainerStyle {
  width: 100%;
}

.dotsIconStyles {
  cursor: grid !important;
  margin-bottom: 3px;
}
