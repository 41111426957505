.label {
  font-size: 16px;
  color: black;
}

.disclamer {
  display: flex;
  gap: 4px;
  padding: 10px;
  background-color: #fff4ca;
  border-radius: 7px;
  margin: 10px 0;
}

.textValue {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 1px;
}

.infoIcon {
  width: 18px !important;
  height: 18px !important;
}
