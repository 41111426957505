.card {
  padding: 16px;
  margin-bottom: 14px;
}

.heading1 {
  font-size: 16px;
  font-weight: 700;
  color: #272522;
}

.heading2 {
  font-size: 16px;
  font-weight: 500;
  color: #272522;
}

.heading3 {
  font-size: 14px;
  font-weight: 500;
  color: #272522;
}

.heading4 {
  font-size: 15px;
  font-weight: 500;
  color: #272522;
}

.heading5 {
  font-size: 14px;
  font-weight: 400;
  color: #272522;
}

.mb {
  margin-bottom: 12px;
}

.mb5 {
  margin-bottom: 5px;
}
.padding_16 {
  padding: 12px;
}

.border_radius_5 {
  border-radius: 5px !important;
}

.purple_background {
  border-radius: 5px;
  background-color: #c0bae880;
  padding: 15px;
}

.purple_background_seemore {
  border-radius: 3px;
  background-color: #c0bae880;
  padding: 15px;
}
.primaryTextOption {
  color: #493ab1;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.accordian_description_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 10px;
  word-break: break-all;
}

.margin_top_18 {
  margin-top: 18px;
}

.accordian_padding {
  padding: 12px;
  padding-bottom: 0px;
}

.accordian_description_title span {
  font-weight: 600;
}

.enableField {
  transform: translate(11px, 0px);
}

.accordian_description_sub_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  word-break: break-all;
}

.accordian_description_sub_title span {
  font-weight: 600;
}

.arrowIcon {
  transform: translate(-3px, -1px) scale(0.7);
}

.backgroundContainer {
  /* padding-right: 15px; */
  height: 33px;
  padding: 0;
  margin-right: -1px;
  padding-top: 5px;
  border-radius: 0px 0px 6px 6px;
}

.backgroundContainerSeemore {
  /* padding-right: 15px; */
  height: 49px;
  padding: 0;
  margin-right: -1px;
  padding-top: 15px;
  padding-right: 3px;
  /* border-radius: 0px 0px 6px 6px; */
}
.modalHeader {
  display: block;
}

.modalHeader div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
}

.modalHeader p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.padding_bottom_std {
  margin-bottom: 12px;
}

.margin_bottom_20px {
  padding: 0px 0px 12px 12px;
}

.webinarSlotsWrapper {
  max-height: 100px;
  overflow-y: scroll;
}

.webinarSlots {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #272522;
}

@media (max-width: 768px) {
  .mb {
    margin-bottom: 14px;
  }

  .heading3 {
    font-size: 12px;
  }
}
