.share_recording_text_style {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02em;
}

.share_recording_sub_text {
  color: rgba(39, 37, 34, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.single_row_style {
  display: flex;
  gap: 10px;
}

.single_label_info_style {
  display: flex;
  align-items: center;
  gap: 4px;
}

.label_style {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.radio_options_style {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media screen and (max-width: 768px) {
  .share_recording_text_style {
    color: rgba(39, 37, 34, 0.85);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .share_recording_sub_text {
    color: rgba(39, 37, 34, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}
